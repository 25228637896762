@import '_fonts';
@import '_mixin';
@import '_variables';
@import '_color_class';
@import '_customize';

* {
  font-family: $font-family-suit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  letter-spacing: -0.425px;

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  box-sizing: border-box;
  -webkit-user-drag: none;
  // @include respond-below(sm) {
  //   font-size: $base-font-xs + px !important;
  // }
  // @include respond-between(sm, md) {
  //   font-size: $base-font-sm + px !important;
  // }
  // @include respond-between(md, lg) {
  //   font-size: $base-font-md + px !important;
  // }
  // @include respond-between(lg, xl) {
  //   font-size: $base-font-lg + px !important;
  // }
  // @include respond-above(xl) {
  //   font-size: $base-font-xl + px !important;
  // }
  @include respond-below(xs) {
    font-size: $base-font-xs + px !important;
    // line-height: ($base-font-xs + ($base-font-xs/5)) + px !important;
  }
  @include respond-between(xs, sm) {
    font-size: $base-font-sm + px !important;
    // line-height: ($base-font-sm + ($base-font-sm/5)) + px !important;
  }
  @include respond-between(ssm, sm) {
    font-size: $base-font-ssm + px !important;
    // line-height: ($base-font-sm + ($base-font-sm/5)) + px !important;
  }
  @include respond-between(sm, md) {
    font-size: $base-font-md + px !important;
    // line-height: ($base-font-md + ($base-font-md/5)) + px !important;
  }
  @include respond-between(md, lg) {
    font-size: $base-font-lg + px !important;
    // line-height: ($base-font-lg + ($base-font-lg/5)) + px !important;
  }
  width: 100vw;
  height: 100vh;
  position: relative;
  &.ios-plus-90 {
    padding-left: calc(env(safe-area-inset-left) - 10px) !important;
    background-color: black;
  }
  &.ios-minus-90 {
    padding-right: calc(env(safe-area-inset-right) - 10px) !important;
    background-color: black;
  }
  &.android-plus-90 {
    padding-left: env(safe-area-inset-left) !important;
    background-color: black;
  }
  &.android-minus-90 {
    padding-right: env(safe-area-inset-right) !important;
    background-color: black;
  }
}

body {
  width: 100%;
  height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

ul,
li,
ol {
  list-style: none;
}

h3 {
  font-size: 1.3rem;
}
h4 {
  font-size: 1.05rem;
}

body.counsel_print_container {
  .counsel_container {
    & .checked_counsel:last-child {
      margin-bottom: 0;
    }
    .checked_counsel {
      margin-bottom: 14.25px;
      background-color: rgb(255, 255, 255);
      position: relative;
      border: 1px solid rgb(235, 235, 235);
      border-radius: 7px;
      padding: 24.8888px 2.5rem 24.8888px 2.25rem;
      display: flex;

      & > div:nth-child(2) {
        height: 100%;
        width: 3.25rem;

        & > div {
          width: 3.25rem;
          height: 3.25rem;
          margin: 0 auto;
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #dbdbdb;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          img {
            max-width: 90%;
            max-height: 90%;
          }
        }
      }

      & > div:last-child {
        height: 100%;
        width: calc(100% - 2.75rem);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-direction: column;

        & > div:first-child {
          font-weight: 500;
          font-size: 14px;
          padding-left: 1rem;
          width: 100%;
          height: 37.3333px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          justify-content: space-between;

          & > div {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: end;
            justify-content: flex-end;

            & > div:first-child {
              font-size: 14px;
              margin-right: 1rem;
            }
          }
        }

        & > div:last-child {
          width: 100%;

          & > div {
            padding: 12.4444px;
            color: #9f9f9f;
            font-size: 14px;
            white-space: pre-wrap;
            line-height: 18.6666px;
          }
        }
      }
    }
  }
}

body.iframe_body {
  .iframe_print_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #f8f9fd !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3rem;
  }

  .check_analyze_print {
    // padding-left: 1px;
    width: 85%;
    height: 82.26%;
    margin: 0 auto;
  }
  #analyze_wrap {
    width: 100%;
    height: 100%;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    font-size: 16px !important;
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
    position: relative;

    & > div:first-child {
      display: flex;
      padding-top: 1rem;
      flex-wrap: wrap;
      gap: 1.6rem 0;
      font-size: 1.5rem;
      width: 100%;
      height: 24.645%;
      & > div:first-child {
        margin-right: 0.85rem;
      }
      & > div:nth-child(2) {
        margin-left: 0.8rem;
      }
      & > div:first-child,
      & > div:nth-child(2) {
        height: 100%;
        padding: 1rem 1.75rem 1.75rem 1.75rem;
        background-color: #fff;
        border-radius: 1rem;
        width: calc(50% - 0.825rem);
        box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
        & > div:first-child {
          width: 100%;
          padding: 0 0 0.5rem 0;
          border-bottom: 1px solid #f1f1f1;
          font-weight: bold;
          min-height: 2rem;
        }
        & > div:last-child {
          margin-top: 0.125rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          position: relative;
          width: 100%;
          height: calc(100% - 2rem);
          & > div {
            margin-top: 0.75rem;
            padding-left: 0.5rem;
            line-height: 1rem;
            & > div:first-child {
              color: #ff382a;
            }
            & > div:last-child {
              margin-top: 1rem;
              color: #7d7d7d;
              & > span {
                color: #d3d3d3;
              }
            }
          }
        }
      }
      & > div:last-child {
        padding: 1rem 0 0 0;
        height: 100%;
        background-color: #fff;
        border-radius: 1rem;
        padding-right: 0;
        box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
        width: calc(50% - 0.825rem);
        margin-right: 0.85rem;
        position: relative;
        & > div:first-child {
          width: auto;
          padding: 0 0 0.5rem 0;
          border-bottom: 1px solid #f1f1f1;
          font-weight: bold;
          min-height: 2rem;
          margin: 0 1.75rem;
        }
        & > div:last-child {
          margin-top: 0.125rem;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          position: relative;
          width: 100%;
          height: calc(100% - 2rem);
          & > div:first-child {
            // transform: scale(1.25);
            top: 15%;
            // left: 17%;
            position: relative;
            text {
              font-size: 3.5rem !important;
            }
          }
          & > div:last-child {
            width: 100%;
            height: 100%;
            // transform: scale(1.05);

            // left: 17.75%;
            position: absolute;
            & > div {
              & > div {
                & > svg {
                  & > g {
                    & > g.recharts-pie-sector {
                      & > path:first-of-type {
                        opacity: 0;
                      }
                    }
                    & > g.recharts-pie-labels {
                      & > g {
                        & > path:first-of-type {
                          opacity: 0;
                        }
                      }
                    }
                  }
                }
                & > svg:not(:root) {
                  overflow: visible;
                }
              }
            }
            text {
              font-size: 14px !important;
            }
          }
        }
      }
    }
    & > div:nth-child(2) {
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
      border-radius: 1rem;
      overflow: hidden;
      margin-right: auto;
      width: 49%;
      margin-top: 0.55%;
      height: 23.5%;
      flex-wrap: wrap;
      margin-left: 51%;
      & > div {
        width: 10%;
        height: 33.3333%;
        & > div:first-child {
          height: 50%;
          background-color: #f7f7f7;
          border-right: 1px solid #e8e8e8 !important;
          border-bottom: 1px solid #e8e8e8;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.75rem;
        }
        & > div:last-child {
          height: 50%;
          font-size: 1.75rem;
          border-right: 1px solid #e8e8e8 !important;
          line-height: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          // color: #fff;
          // background-color: #ff6c59;
        }
      }
    }
    & > div:nth-child(3),
    & > div:last-child {
      margin-top: 4px;
      box-shadow: rgba(0, 0, 0, 0.02) 1px 1px 3px 6px;
      background-color: #fff;
      padding-top: 13px;
      padding-bottom: 5px;
      border-radius: 1rem;
      height: 23%;
      width: 100%;
      position: relative;
      & > div:first-child {
        font-weight: 700;
        margin-bottom: -6px;
        padding-left: 1.5rem;
        font-size: 1.275rem;
      }
      & > div:last-child {
        width: 100%;
        height: 80%;
        transform: translateX(-16px);
        & > div {
          transform: scale(0.975, 0.9);
        }
      }
      tspan {
        font-size: 1.275rem;
      }
    }
    // #analyze_contents_top {
    //   height: 32%;
    //   -webkit-box-pack: justify;
    //   justify-content: space-between;
    //   display: flex;
    //   width: 100%;
    //   .analyze_student_info {
    //     font-size: 16px !important;
    //     width: 100%;
    //     height: 20%;
    //     display: flex;
    //     &:first-child {
    //       &:first-child {
    //         border-top: none;
    //       }
    //       &:last-child {
    //         border-top: none;
    //       }
    //     }
    //     & > div:first-child {
    //       width: 35%;
    //       padding: 1rem;
    //       border-top: 1px solid #dbdbdb;
    //       border-right: 1px solid #dbdbdb;
    //       background-color: #f0f0f0;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }
    //     & > div:last-child {
    //       width: 65%;
    //       padding: 1rem;
    //       border-top: 1px solid #dbdbdb;
    //       background-color: #fff;
    //       display: flex;
    //       justify-content: flex-start;
    //       align-items: center;
    //     }
    //   }
    //   .recharts-pie-labels {
    //     g {
    //       g {
    //         text {
    //           font-size: 18px !important;
    //         }
    //       }
    //     }
    //   }
    //   & > div {
    //     width: 49%;
    //     height: 100%;
    //     border: 2px solid #dbdbdb;
    //     border-radius: 0.35rem;
    //   }
    //   & > div:first-child {
    //     flex-wrap: wrap;
    //     display: flex;
    //   }
    //   & > div:last-child {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: relative;
    //     & > div:last-child {
    //       width: 100%;
    //       height: 100%;
    //       position: absolute;
    //       z-index: -99;
    //       top: 50%;
    //       left: 50%;
    //       transform: translate(-50%, -50%);
    //     }
    //   }
    // }
    // #analyze_contents_middle {
    //   width: 100%;
    //   height: 15%;
    //   border: 1px solid #dbdbdb;
    //   border-radius: 0.45rem;
    //   overflow: hidden;
    //   display: flex;
    //   & > div {
    //     width: 100%;
    //     height: 100%;
    //     & > div:first-child {
    //       height: 50%;
    //       background-color: #f6f6f6;
    //       border-right: 1px solid #dbdbdb !important;
    //       border-bottom: 1px solid #dbdbdb;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }
    //     & > div:last-child {
    //       height: 50%;
    //       font-size: 1.125rem;
    //       border-right: 1px solid #dbdbdb !important;
    //       line-height: 0;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //       &[result = 'success'] {
    //         color: #fff;
    //         background-color: #71c5ff;
    //       }
    //       &[result = 'false' ] {
    //         color: #fff;
    //         background-color: #ff6c59;
    //       }
    //       &[result = 'null'] {
    //         background-color: #fff;
    //       }
    //       &:last-child {
    //         & > div {
    //           border-right: none;
    //         }
    //       }
    //     }
    //   }
    // }
    // #analyze_contents_bottom {
    //   width: 100%;
    //   height: 53%;
    //   border: 1px solid #dbdbdb;
    //   border-radius: 0.45rem;
    //   padding: 1rem;
    //   overflow: hidden;
    //   & > div:first-child {
    //     font-weight: 700;
    //     margin-bottom: 0.5rem;
    //     font-size: 1.75rem;
    //     padding-left: 0.5rem;
    //   }
    // }
  }
}

body.study_print_container {
  .iframe_print_wrap {
    width: 100%;
    height: 100%;
    min-height: 297mm;
    display: flex;
    background-color: #f8f9fd !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .study_container {
    & > div.study-report {
      height: auto;
      overflow: hidden;
      padding: 28px;
      background-color: #fff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      // margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      & > div.study-report__header {
        white-space: nowrap;
        height: auto;
        overflow: hidden;
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > div {
          height: auto;
          // font-size: 16px;
          font-weight: bold;
        }
      }
      & > div.study-report__contents {
        height: auto;
        overflow: hidden;
        width: 100%;
        // font-size: 16px;
        color: #979797;
        line-height: 18px;
        & > p {
          width: 100%;
          white-space: pre-wrap;
          min-height: 18px;
          line-height: 18px;
        }
      }
      & > div.study-report__footer {
        white-space: nowrap;
        height: auto;
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        // font-size: 16px;
        margin-top: 12px;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill::first-line {
  font-size: 1rem;
}

div#root {
  position: relative;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-3.5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(3.5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  from {
    border-left-color: transparent;
  }
  50% {
    border-left-color: #858585;
  }
  to {
    border-left-color: transparent;
  }
}

@keyframes delayfadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dalay-fade-in {
  animation: delayfadein 2s ease-in-out 1s forwards;
  opacity: 0;
}

.shake-animation {
  animation: shake 0.1s infinite;
}

/* line-chart */
// .custom-line-chart {
//   .recharts-xAxis {
//     .recharts-cartesian-axis-tick:first-child, .recharts-cartesian-axis-tick:last-child {
//       display: none;
//     }
//   }
// }

.SUIT-100 {
  font-family: 'SUIT';
  font-weight: 100;
}
.SUIT-200 {
  font-family: 'SUIT';
  font-weight: 200;
}
.SUIT-300 {
  font-family: 'SUIT';
  font-weight: 300;
}
.SUIT-400 {
  font-family: 'SUIT';
  font-weight: 400;
}
.SUIT-500 {
  font-family: 'SUIT';
  font-weight: 500;
}
.SUIT-600 {
  font-family: 'SUIT';
  font-weight: 600;
}
.SUIT-700 {
  font-family: 'SUIT';
  font-weight: 700;
}
.SUIT-800 {
  font-family: 'SUIT';
  font-weight: 800;
}
.SUIT-900 {
  font-family: 'SUIT';
  font-weight: 900;
}
.Apple-300 {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 300;
}
.Apple-500 {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 500;
}
.Apple-700 {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 700;
}
.Apple-800 {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 800;
}
.TT-normal {
  font-family: 'TTTogether';
  font-weight: normal;
}
.Nanum-normal {
  font-family: 'NanumSquare';
  font-weight: normal;
}
.Nanum-bold {
  font-family: 'NanumSquare';
  font-weight: 800;
}
.KCC-normal {
  font-family: 'KCC-Ganpan';
  font-weight: normal;
}

.Inconsolata {
  font-family: 'Inconsolata';
  font-weight: 400;
}

@media (max-width: 1023px) {
  .recharts-cartesian-grid-horizontal {
    line {
      stroke: rgb(230, 230, 230);
    }
  }
}

.small-kor {
  .g_kor_span {
    font-size: 80% !important;
  }
}

.result-zoom {
  border-bottom: 1px solid #616161;
  top: 4px;
  margin-left: 2px;
  margin-right: 2px;
  &.example {
    border-color: #9e9e9e;
  }
}

.white--text {
  color: #fff !important;
  caret-color: #fff !important;
}

.sa_input {
  background-color: #f5f5f5;
  border-radius: 35px;
  padding: 8px 16px;
  height: 100%;
  &:focus-visible {
    outline: none;
  }
}

.d-inline-block {
  display: inline-block;
}

.pos-rel {
  position: relative;
}

.success--text {
  color: #4620e9;
  font-weight: 500;
}

.info2--text {
  color: #4620e9;
  font-weight: 500;
}

@keyframes blink {
  0% {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
  50% {
    transform: scale(0.9) translateY(-55%);
    opacity: 0.3;
  }
  100% {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
}

@keyframes skeletonwave {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.blink-right-button {
  animation: blink 2s linear infinite;
  svg {
    color: #ff7371 !important;
    fill: #ff7371 !important;
  }
}

.u-l {
  text-decoration: underline;
  text-underline-position: under;
}
.grammar-custom-scrollbar {
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(210, 210, 210, 0.6);
    border-radius: 5px;
  }
}

#register-wrapper {
  .selection-root {
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    position: relative;
    width: 26rem;
    height: 3rem;
    margin-bottom: 0.5rem;

    @media (max-width: 769px) {
      height: 3.5rem;
    }
  }

  .overlay {
    height: 100%;
    left: 0;
    margin: 0 auto;
    overflow-y: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.25s ease;
    width: 100%;
    z-index: 20000;
  }

  .selection-header {
    cursor: pointer;
    overflow: hidden;
    padding-right: 1.75rem;
    padding: 0.5rem 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #9ca3af;
  }

  .selection-entries {
    transform: translateX(-1px);
    background-color: #ffffff;
    border-radius: 0.375rem;
    border: 1px solid #d8d8d8;
    // margin-top: 1px;
    width: calc(100% + 0.125rem);
    overflow-x: auto;
    padding: 0.5rem;
    position: absolute;
    z-index: 30000;
  }

  .entry-level {
    list-style-type: none;
    margin: 0;
    max-height: 14.0625rem;
    overflow: auto;
    padding: 0;
    &:first-child {
      width: 39.7826%;
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }
    &:nth-child(3) {
      width: 60.2174%;
      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }
    }
  }

  .entry-item {
    border-radius: 0.25rem;
    cursor: pointer;
    overflow: hidden;
    padding: 0.35rem 0.5rem;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #aaa;
    font-size: 0.9rem;
  }

  .entry-item:hover,
  .entry-item__selected {
    // background-color: antiquewhite;
  }

  .entry-item__nestable {
    padding-right: 1.5rem;
  }

  // .entry-item__nestable::after {
  //   content: '〉';
  //   position: absolute;
  //   right: 8px;
  // }

  .select-wrapper {
    position: relative;
  }

  // .select-wrapper::after {
  //   color: #2b2c2e;
  //   content: '\2304';
  //   font-family: 'Roboto';
  //   font-size: 1.6em;
  //   font-weight: 400;
  //   pointer-events: none;
  //   position: absolute;
  //   right: 8px;
  //   top: -8px;
  // }

  .hidden {
    display: none;
  }

  .hidden {
    display: none;
  }

  .flex {
    display: flex;
  }

  .flex-row {
    flex-direction: row;
  }
}

.selectio-value {
  @media (max-width: 959px) {
    padding-top: 1px;
  }
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

.blinking {
  animation: blink-animation 1s steps(2, start) infinite;
}

@keyframes blink-animation-2 {
  /* 0 ~ 약 28.57% (0.2초) 까지는 텍스트 안보임 */
  0% {
    opacity: 0;
  }
  28.57% {
    opacity: 0;
  }

  /* 그 다음 약 28.58%부터 100% (0.5초) 까지는 텍스트 보임 */
  28.58% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.blinking-text {
  /* 애니메이션 지속 시간은 0.7초, 무한 반복 */
  animation: blink-animation-2 0.7s linear infinite;
}

// react-tooltip base style setter
#first-tooltip {
  padding: 0.5rem 1rem;
  border-radius: 0.75em;

  .react-tooltip-arrow {
    width: 0;
    height: 0;
    border-left: 0.75rem solid transparent; /* 왼쪽 투명 */
    border-right: 0.75rem solid transparent; /* 오른쪽 투명 */
    border-top: 1.5rem solid #592aec; /* 위쪽 색상 */
    transform: translateX(50%) rotate(270deg); /* 중앙 정렬 */

    @media (max-width: 959px) {
      border-left: 0.5rem solid transparent; /* 왼쪽 투명 */
      border-right: 0.5rem solid transparent; /* 오른쪽 투명 */
      border-top: 1rem solid #592aec; /* 위쪽 색상 */
      transform: translateX(35%) rotate(270deg); /* 중앙 정렬 */
    }

    @media (min-width: 960px) and (max-width: 977px) {
      border-left: 0.5rem solid transparent; /* 왼쪽 투명 */
      border-right: 0.5rem solid transparent; /* 오른쪽 투명 */
      border-top: 1rem solid #592aec; /* 위쪽 색상 */
      transform: translateX(0%) translateY(-50%) rotate(180deg); /* 중앙 정렬 */
    }
  }

  @media (min-width: 768px) and (max-width: 959px) {
    .text-container {
      padding-top: 0.1rem;
    }
  }
}

#second-tooltip {
  padding: 0.5rem 1rem;
  border-radius: 0.75em;

  .react-tooltip-arrow {
    width: 0;
    height: 0;
    border-left: 0.75rem solid transparent; /* 왼쪽 투명 */
    border-right: 0.75rem solid transparent; /* 오른쪽 투명 */
    border-top: 1.5rem solid #592aec; /* 위쪽 색상 */
    transform: translateX(50%) rotate(270deg); /* 중앙 정렬 */

    @media (max-width: 959px) {
      border-left: 0.5rem solid transparent; /* 왼쪽 투명 */
      border-right: 0.5rem solid transparent; /* 오른쪽 투명 */
      border-top: 1rem solid #592aec; /* 위쪽 색상 */
      transform: translateX(35%) rotate(270deg); /* 중앙 정렬 */
    }

    @media (min-width: 960px) and (max-width: 977px) {
      border-left: 0.5rem solid transparent; /* 왼쪽 투명 */
      border-right: 0.5rem solid transparent; /* 오른쪽 투명 */
      border-top: 1rem solid #592aec; /* 위쪽 색상 */
      transform: translateX(0%) translateY(50%) rotate(360deg); /* 중앙 정렬 */
    }
  }
  @media (min-width: 768px) and (max-width: 959px) {
    .text-container {
      padding-top: 0.1rem;
    }
  }
}

#schedule-tooltip {
  padding: 0.5rem 1rem;
  border-radius: 0.75em;
  transform: scale(1.15);

  @media (min-width: 1265px) and (max-width: 1903px) {
    transform: scale(1.3);
  }

  @media (max-width: 1264px) {
    transform: scale(1.4);
  }

  @media (max-width: 1187px) {
    transform: scale(1.3);
  }

  @media (max-width: 960px) and (min-height: 405px) {
    transform: scale(1.4);
  }

  @media (max-width: 960px) and (max-height: 404px) {
    transform: scale(1.2);
  }

  @media (max-width: 768px) {
    transform: scale(1.1);
  }

  .react-tooltip-arrow {
    width: 0;
    height: 0;
    border-left: 0.75rem solid transparent; /* 왼쪽 투명 */
    border-right: 0.75rem solid transparent; /* 오른쪽 투명 */
    border-top: 1.5rem solid #592aec; /* 위쪽 색상 */
    transform: translate(55%, -52%) rotate(180deg); /* 중앙 정렬 */

    // @media (max-width: 959px) {
    //   border-left: 0.5rem solid transparent; /* 왼쪽 투명 */
    //   border-right: 0.5rem solid transparent; /* 오른쪽 투명 */
    //   border-top: 1rem solid #592aec; /* 위쪽 색상 */
    //   transform: translateX(35%) rotate(270deg); /* 중앙 정렬 */
    // }

    // @media (min-width: 960px) and (max-width: 977px) {
    //   border-left: 0.5rem solid transparent; /* 왼쪽 투명 */
    //   border-right: 0.5rem solid transparent; /* 오른쪽 투명 */
    //   border-top: 1rem solid #592aec; /* 위쪽 색상 */
    //   transform: translateX(0%) translateY(50%) rotate(360deg); /* 중앙 정렬 */
    // }
  }
}
