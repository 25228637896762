a,
img {
  -webkit-user-drag: none !important;
}

*:focus-visible {
  outline: none !important;
}

* {
  -webkit-tap-highlight-color: transparent;
}

img {
  -webkit-touch-callout: none !important;
  pointer-events: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: -moz-none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.main_box {
  width: 75%;
  height: 60%;
  cursor: pointer;
  position: relative;
  &:active img {
    transform: scale(0.95);
    &.main_grammar {
      transform: scale(1.1);
    }
  }
}

.icon_box::after {
  border-top: 1px solid #8162fd;
  display: block;
  margin-top: 0.45rem;
  width: 3rem;
  content: '';
  transform: translateY(10px);
}

.exit_box::before {
  margin-left: 0.6rem;
  // border-top: 1px solid #8162fd;
  display: block;
  width: 3rem;
  content: '';
}

.c_sky {
  color: #00aeff;
}

.c_blue {
  color: #2973d0;
}

.c_red {
  color: #ff3f3f;
}

.c_green {
  color: #44e452;
}

.c_orange {
  color: #ffa500;
}

.c_gray {
  color: #9a9a9a;
}

.c_purple {
  color: #4620e9;
}

.c_purple_2 {
  color: #cc3fce;
}

// React-Datepicker
.react-datepicker__input-container {
  width: unset !important;
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.23921568627450981);
    border-top: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.23921568627450981);
    margin-bottom: 1px;
    cursor: pointer;
    transform: translate(-100%);
  }
  .datepicker_input {
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid #c5c5c5 !important;
    width: 9rem;
    height: unset;
    padding: calc(0.375rem - 1px) 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    text-align: center;
    &:focus-visible {
      outline: none !important;
    }
  }
  &:focus-visible {
    outline: none !important;
  }
}

@media (max-width: 1023px) {
  .react-datepicker__input-container {
    .datepicker_input {
      border-color: #f0f0f0 !important;
    }
  }
}

.react-datepicker__portal {
  background-color: rgba(33, 33, 33, 0.46) !important;
}
.datepicker {
  padding: 0.75rem 1rem 0.75rem 1rem;
  width: 21.6rem;
  height: 24.3rem;
  font-size: 1rem !important;
  border: none !important;
  border-radius: unset !important;

  *:focus-visible {
    outline: none !important;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__header {
    width: 100%;
    height: 6rem;
    border: none;
    background-color: unset;
    padding: 0;
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: #dbdbdb;
        width: 2.8rem;
        height: 2.8rem;
        line-height: 2.8rem;
        margin: 0;
      }
    }
  }
  .react-datepicker__year--container {
    margin-bottom: 2px;
  }
  .react-datepicker__month {
    margin: 0;
    .react-datepicker__week:first-child {
      display: flex;
      justify-content: flex-end;
    }
    .react-datepicker__week:last-child {
      display: flex;
      justify-content: flex-start;
    }
    .react-datepicker__month-wrapper {
      width: 19.6rem;
      height: 4.2rem;
      transform: translate(0, -1.5rem);
      .react-datepicker__month-text {
        width: 6rem;
        height: 2.8rem;
        line-height: 2.8rem;
      }
      .react-datepicker__month--selected {
        box-shadow: 0 0 0 1px #652ae9;
        border-radius: 0.7rem !important;
        background-color: #4620e9 !important;
        color: #fff !important;
      }
    }
    .react-datepicker__day {
      color: #165f5f;
      width: 2.8rem;
      height: 2.4rem;
      line-height: 2.4rem;
      margin: 0.2rem 0;
      &:hover {
        background-color: unset;
      }
      &.saturday {
        color: #0097e7;
      }
      &.sunday {
        color: #e9121d;
      }
      &.react-datepicker__day--outside-month {
        display: none;
      }
      &.react-datepicker__day--disabled {
        color: #dbdbdb;
      }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--range-end {
      background: radial-gradient(circle closest-side, #4620e9 75%, #652ae9 85%, transparent 90%) !important;
      color: #fff !important;
    }
  }
  .react-datepicker__year {
    width: 19.6rem;
    height: 16.8rem;
    margin: 0;
    transform: translate(0, -1.5rem);
    .react-datepicker__year-wrapper {
      max-width: unset;
      .react-datepicker__year-text {
        width: calc(50% - 2rem);
        height: 2.8rem;
        line-height: 2.8rem;
        margin: 0 1rem 0 1rem;
      }
      .react-datepicker__year-text--selected {
        box-shadow: 0 0 0 1px #652ae9;
        border-radius: 0.7rem !important;
        background-color: #4620e9 !important;
        color: #fff !important;
      }
    }
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: unset;
    background-color: unset;
    color: unset;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: unset;
    background-color: unset;
  }
  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--in-range:hover,
  .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    border-radius: unset;
    background-color: unset;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: unset;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__day--in-range {
    background-color: #f8f6fe !important;
  }
  .react-datepicker__day--selected .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: unset;
    background-color: unset;
    color: unset;
  }
}

.rangepicker {
  width: 100%;
  height: 22.8rem;
  font-size: 1rem !important;
  border: none !important;
  border-radius: unset !important;
  display: flex !important;
  justify-content: space-around;
  margin-bottom: 0.7rem;

  .react-datepicker__header {
    width: 100%;
    height: 6rem;
    border: none;
    background-color: unset;
    padding: 0;
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: #dbdbdb;
        width: 2.8rem;
        height: 2.8rem;
        line-height: 2.8rem;
        margin: 0;
      }
    }
  }
  .react-datepicker__month {
    margin: 0;
    .react-datepicker__week:first-child {
      display: flex;
      justify-content: flex-end;
    }
    .react-datepicker__week:last-child {
      display: flex;
      justify-content: flex-start;
    }
    .react-datepicker__month-wrapper {
      width: 19.6rem;
      height: 4.2rem;
      transform: translate(0, -1.5rem);
      .react-datepicker__month-text {
        width: 6rem;
        height: 2.8rem;
        line-height: 2.8rem;
      }
      .react-datepicker__month--selected {
        box-shadow: 0 0 0 1px #652ae9;
        border-radius: 0.7rem !important;
        background-color: #4620e9 !important;
        color: #fff !important;
      }
    }
    .react-datepicker__day {
      color: #165f5f;
      width: 2.8rem;
      height: 2.4rem;
      line-height: 2.4rem;
      margin: 0.2rem 0;
      &:hover {
        background-color: unset;
      }
      &.saturday {
        color: #0097e7;
      }
      &.sunday {
        color: #e9121d;
      }
      &.react-datepicker__day--outside-month {
        display: none;
      }
      &.react-datepicker__day--disabled {
        color: #dbdbdb;
      }
    }

    .react-datepicker__day--selected {
      background: radial-gradient(circle closest-side, #4620e9 75%, #652ae9 85%, transparent 90%) !important;
      color: #fff !important;
    }

    .react-datepicker__day--selected.react-datepicker__day--range-start:not(.react-datepicker__day--range-end) {
      background: radial-gradient(circle closest-side, #4620e9 75%, #652ae9 85%, transparent 90%),
        linear-gradient(to right, transparent 50%, #f8f6fe 50%) !important;
      color: #fff !important;
    }

    .react-datepicker__day--range-end:not(.react-datepicker__day--range-start) {
      background: radial-gradient(circle closest-side, #4620e9 75%, #652ae9 85%, transparent 90%),
        linear-gradient(to left, transparent 50%, #f8f6fe 50%) !important;
      color: #fff !important;
    }
  }
  .react-datepicker__year {
    width: 19.6rem;
    height: 16.8rem;
    margin: 0;
    transform: translate(0, -1.5rem);
    .react-datepicker__year-wrapper {
      max-width: unset;
      .react-datepicker__year-text {
        width: calc(50% - 2rem);
        height: 2.8rem;
        line-height: 2.8rem;
        margin: 0 1rem 0 1rem;
      }
      .react-datepicker__year-text--selected {
        box-shadow: 0 0 0 1px #652ae9;
        border-radius: 0.7rem !important;
        background-color: #4620e9 !important;
        color: #fff !important;
      }
    }
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: unset;
    background-color: unset;
    color: unset;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: unset;
    background-color: unset;
  }
  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--in-range:hover,
  .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    border-radius: unset;
    background-color: unset;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: unset;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__day--in-range {
    border-radius: unset;
    background-color: #f8f6fe !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: unset;
    background-color: unset !important;
    color: unset;
  }
}

// CSSTransition
.horizontal-right-enter,
.horizontal-left-enter,
.vertical-up-enter,
.vertical-down-enter {
  opacity: 0;
}

.horizontal-right-enter {
  transform: translateX(100%);
  filter: blur(5px);
}
.horizontal-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  filter: blur(0);
  transition: opacity 300ms, transform 300ms, filter 300ms;
}
.horizontal-right-exit {
  opacity: 1;
  filter: blur(5px);
}
.horizontal-right-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.horizontal-left-enter {
  transform: translateX(-100%);
  filter: blur(10px);
}
.horizontal-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms, filter 300ms;
}
.horizontal-left-exit {
  opacity: 1;
  filter: blur(5px);
}
.horizontal-left-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.vertical-up-enter {
  transform: translateY(-100%);
  filter: blur(5px);
}
.vertical-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms, filter 300ms;
}
.vertical-up-exit {
  opacity: 1;
  filter: blur(5px);
}
.vertical-up-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.vertical-down-enter {
  transform: translateY(100%);
  filter: blur(5px);
}
.vertical-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms, filter 300ms;
}
.vertical-down-exit {
  opacity: 1;
  filter: blur(5px);
}
.vertical-down-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

// ! grammar-animation
.g-horizontal-right-enter {
  transform: translateX(100%);
}
.g-horizontal-right-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.g-horizontal-right-exit {
}
.g-horizontal-right-exit-active {
  // transform: translateX(-100%);
  transition: transform 300ms;
}

.g-horizontal-left-enter {
  transform: translateX(-100%);
}
.g-horizontal-left-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

.g-horizontal-left-exit-active {
  transition: transform 300ms;
}

// * 추가
/* 오른쪽에서 왼쪽으로 (1 -> 2) */
.step-slide-left-enter {
  transform: translateX(100%);
  opacity: 0;
}
.step-slide-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
.step-slide-left-exit {
  transform: translateX(0);
  opacity: 1;
}
.step-slide-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}

/* 왼쪽에서 오른쪽으로 (2 -> 1) */
.step-slide-right-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.step-slide-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
.step-slide-right-exit {
  transform: translateX(0);
  opacity: 1;
}
.step-slide-right-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
