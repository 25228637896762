$red: #e9121d;
$danger: #e9121d;
$green: #2ece73;
$yellow-green: #32ea88;
$orange: #fc6500;
$yellow: #fcbe00;
$blue: #2baed1;
$sky-blue: #e3f8ff;
$purple: #4620e9;
$purple-2: #cc3fce;
$light-purple: #ecd6fd;
$light-purple-2: #f4eeff;
$gray-1: #9f9f9f;
$gray-2: #dbdbdb;
$gray-3: #ebebeb;
$gray-4: #f0f0f0;
$gray-5: #f8f9fa;
$cyan: #3fbace;
$cyan-2: #4fb9c0;
$light-cyan: #e3f8ff;
$black: #000000;
$black-2: #404040;
$white: #ffffff;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-apple: 'Apple SD Gothic Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-suit: 'SUIT';
$font-family-tttogether: 'TTTogether';
$font-family-kcc-ganpan: 'KCC-Ganpan';
$base-font-xs: 8;
$base-font-ssm: 8;
$base-font-sm: 9;
$base-font-md: 11;
$base-font-lg: 12;
