// @import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
@import url('https://webfontworld.github.io/gmarket/GmarketSans.css');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@600&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoL.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoL.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoL.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoM.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoM.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoM.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoB.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoB.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoB.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-weight: 800;
  font-style: normal;
  font-display: block;
  src: url('../../assets/fonts/AppleSDGothicNeoEB.woff2') format('woff2'),
    url('../../assets/fonts/AppleSDGothicNeoEB.woff') format('woff'),
    url('../../assets/fonts/AppleSDGothicNeoEB.ttf') format('truetype');
  unicode-range: U+0020-007E, U+1100-11F9, U+3000-303F, U+3131-318E, U+327F-327F, U+AC00-D7A3, U+FF01-FF60;
}

@font-face {
  font-family: 'SUIT';
  font-weight: 900;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard Black'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Black.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Black.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 800;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard ExtraBold'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-ExtraBold.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-ExtraBold.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 700;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard Bold'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Bold.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Bold.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 600;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard SemiBold'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-SemiBold.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-SemiBold.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 500;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard Medium'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Medium.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Medium.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard Regular'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Regular.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard Light'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Light.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Light.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 200;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard ExtraLight'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-ExtraLight.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-ExtraLight.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-weight: 100;
  font-display: swap;
  unicode-range: U+0041-005A U+0061-007A;
  src: local('Pretendard Thin'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff2/Pretendard-Thin.woff2')
      format('woff2'),
    url('https://fastly.jsdelivr.net/gh/orioncactus/pretendard/packages/pretendard/dist/web/static/woff/Pretendard-Thin.woff')
      format('woff');
}

@font-face {
  font-family: 'SUIT-REAL';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTTogether';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/TTTogetherA.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KCC-Ganpan';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/KCC-Ganpan.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NanumSquare';
  src: url('../../assets/fonts/NanumSquareR.woff2') format('woff2'),
    url('../../assets/fonts/NanumSquareR.woff') format('woff'),
    url('../../assets/fonts/NanumSquareR.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'NanumSquare';
  src: url('../../assets/fonts/NanumSquareEB.woff2') format('woff2'),
    url('../../assets/fonts/NanumSquareEB.woff') format('woff'),
    url('../../assets/fonts/NanumSquareEB.ttf') format('truetype');
  font-weight: 800;
}

// .palanquin-thin {
//   font-family: 'Palanquin', sans-serif;
//   font-weight: 100;
//   font-style: normal;
// }

// .palanquin-extralight {
//   font-family: 'Palanquin', sans-serif;
//   font-weight: 200;
//   font-style: normal;
// }

// .palanquin-light {
//   font-family: 'Palanquin', sans-serif;
//   font-weight: 300;
//   font-style: normal;
// }

// .palanquin-regular {
//   font-family: 'Palanquin', sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }

// .palanquin-medium {
//   font-family: 'Palanquin', sans-serif !important;
//   font-weight: 500;
//   letter-spacing: -2px;
//   font-style: normal;
// }

// .palanquin-semibold {
//   font-family: 'Palanquin', sans-serif;
//   font-weight: 600;
//   font-style: normal;
// }

// .palanquin-bold {
//   font-family: 'Palanquin', sans-serif;
//   font-weight: 700;
//   font-style: normal;
// }
